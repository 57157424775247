import { RoleRetaguarda } from "../../components/SideBar/config";
import { getAuthoritiesUsuarioLogado } from "../../model/usuarioLogado";

const eUsuarioAdminRenegociacao = (): boolean => {
  const userAuthorities = getAuthoritiesUsuarioLogado();
  return userAuthorities?.includes(
    RoleRetaguarda.ROLE_USER_FINANCEIRO_ADMIN_RENEGOCIACAO
  )!;
};

const eUsuarioResponseAdesao = (): boolean => {
  const userAuthorities = getAuthoritiesUsuarioLogado();
  return userAuthorities?.includes(
    RoleRetaguarda.ROLE_RESPONSAVEL_ADESAO_CONTRATO_ANEXADO_ATUALIZACAO
  )!;
};

const isUsuarioComercial = (): boolean => {
  const userAuthorities = getAuthoritiesUsuarioLogado();
  return userAuthorities?.includes(RoleRetaguarda.ROLE_COMERCIAL_B2C)!;
};

const isUsuarioCredito = (): boolean => {
  const userAuthorities = getAuthoritiesUsuarioLogado();
  return userAuthorities?.includes(RoleRetaguarda.ROLE_CREDITO)!;
};

const isUsuarioEdicaoDadosOCRDocumento = (): boolean => {
  const userAuthorities = getAuthoritiesUsuarioLogado();
  return userAuthorities?.includes(RoleRetaguarda.ROLE_DADOS_DOCUMENTO_OCR_EDICAO)!;
};

const isUsuarioAlteracaoAdministradora = (): boolean => {
  const userAuthorities = getAuthoritiesUsuarioLogado();
  return userAuthorities?.includes(RoleRetaguarda.ROLE_ADMINISTRADORA_PROPOSTA_ATUALIZACAO)!;
};

export {
  eUsuarioAdminRenegociacao,
  isUsuarioComercial,
  isUsuarioCredito,
  eUsuarioResponseAdesao,
  isUsuarioEdicaoDadosOCRDocumento,
  isUsuarioAlteracaoAdministradora
};
